import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
// import { Blob } from "../components/atoms"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/postList"
import Contact from "../components/contact"
// import Image from "../components/image"
import PublicationsGrid from "../components/publicationsGrid"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { published: { eq: true } } }
        limit: 5
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 100)
            frontmatter {
              title
              date(formatString: "MMMM Do YYYY")
              published
              featured
              tags
              description
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return (
    <Layout activePage="/">
      <Seo />
      <div className="mt-12 flex flex-col-reverse lg:flex-row items-center lg:justify-between lg:space-x-12">
        <div>
          <h1 className="mt-12 mb-12 mt-0 max-w-3xl text-3xl sm:text-4xl text-primary font-semibold sm:text-left md:text-center lg:text-left">
            Welcome
        <br className="mb-12" />
            <p className="mt-0 max-w-3xl text-base sm:text-base sm:text-left md:text-center lg:text-left leading-4">
              This site delivers news and update on TU Dublin Research Project 
              </p>
              <br className="mb-0" />
              <p className="font-serif italic font-light mt-0 max-w-6xl text-2xl text-left leading-8">"Increasing the efficiency of silicon photovoltaic cells using an industrially-viable atmospheric dry etch nanotexturing process".
            </p>
            <br className="mb-0" />
            <p className="mt-0 max-w-6xl text-sm sm:text-sm text-left">
              The research activity is currently conducted by Longteng Zhang in <a href="https://tudublin.ie" className="text-accent hover:text-accent hover:underline no-und" target="_blank" rel="noopener noreferrer">TU Dublin Tallaght</a>, and funded by <a href="https://research.ie" className="text-accent hover:text-accent hover:underline no-und" target="_blank" rel="noopener noreferrer">Irish Research Council</a>.
            </p>
            <p></p>
            <p className="mt-0 max-w-6xl text-sm sm:text-sm text-left">
              Please check out our recent activity in the news post. Or learn about this research and related projects in <a href="/about" className="text-accent hover:text-accent hover:underline no-und">About</a> section.
            </p>
            <p className="mt-0 max-w-6xl text-sm sm:text-sm text-left">
              We collaborate with <a href="http://nines-pv.com" className="text-accent hover:text-accent hover:underline no-und" target="_blank" rel="noopener noreferrer">Nines PV</a> to utilize the ADE technology and discover its applications.
            </p>
          </h1>
        </div>

        {/* <div className="relative">
          <Blob />
          <div className="absolute w-full h-full top-0 flex items-center justify-center">
            <div className="h-32 w-32 md:h-40 md:w-40 lg:h-48 lg:w-48 rounded-full overflow-hidden">
              <Image />
            </div>
          </div>
        </div> */}
      </div>

      <PostList posts={data.allMdx.edges} showHeading />

      <h2 className="mt-24 font-normal text-accent tracking-widestest mb-8">
        RECENT PUBLICATIONS
        </h2>
      <PublicationsGrid limit={2} />
      <div className="mt-4 border-t pt-4 border-subtle" aria-label="hidden">
        <div className="inline-block pr-3 group  hover:text-accent text-lg">
          <Link to="/about#publications">
            -&gt;
                <span className="ml-1 transition inline-block transform duration-500 hover:translate-x-3 ">
              All Publications
                </span>
          </Link>
        </div>
      </div>
      <Contact />
    </Layout>
  )
}

export default IndexPage
